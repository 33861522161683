import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'ui-neumorphism'
import 'ui-neumorphism/dist/index.css'
import './Footer.css'

export default () => (
 <div>
   <h2 className="taCenter">
     <Button><Link to="/contact">Contact Gold Software Systems</Link></Button>
   </h2>
   <br />
   <footer className="footer">
     <div className="container taCenter">
       <span>
         © Copyright {new Date().getFullYear()} All rights reserved. Crafted by {' '}
         <a href="https://www.lifelogs.com/">Gold Software Systems</a>.
       </span>
       <div>
         From a template by {' '}
         <a href="https://thriveweb.com.au/">Thrive</a>.
       </div>
     </div>
   </footer>
 </div>
)
