import React from 'react'
import { Zap } from 'react-feather'
import './Logo.css'

export default () => (
 <Zap className="Logo"
      style={{
      //backgroundImage: `url(/images/logo.svg)`,
      //backgroundImage: `url(/images/wave.jpeg)`,
      //backgroundImage: `url(/images/logo.png)`,
    }}
  />
)
